import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';

export const ACCOUNTING_DOCUMENTS_MODULE: Module = {
	id: Collections.AccountingDocuments,
	name: 'ACCOUNTING_DOCUMENTS',
	schema: {
		properties: {
			id: {type: 'string'},
			name: {type: 'string'},
			description: {type: 'string'}
		}
	},
	layout: {
		editTitleKey: 'name',
		sort: {active: 'name', direction: 'asc'},
		instance: {
			actions: [
				{
					value: it => JSX(<jms-e-account-document-create-invocation id={it.id}></jms-e-account-document-create-invocation>)
				}
			],
			segments: [{
				fields: [
					'/name',
					'/description'
				]
			}]
		},
		table: {
			hideAdd: true,
			hideEdit: true,
			hideImport: true,
			hideExport: true,
			hideDelete: true,
			tableColumns: [
				{key: '/name', label: 'NAME'},
				{key: '/description', label: 'DESCRIPTION'},
			]
		}
	},
	definitions: {
		name: {label: 'NAME'},
		description: {
			label: 'DESCRIPTION',
			component: {
				type: 'textarea'
			}
		}
	}
}