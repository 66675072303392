import {PipeType} from '../../../enums/pipe-type.enum';
import {Collections} from '../../../interfaces/collections';
import {SHARED_CONFIG} from '../../../consts/shared-config.const';

function years() {
	const final = [];

	for (let i = SHARED_CONFIG.startingYear; i < new Date().getFullYear() + 1; i++) {
		final.push({value: i, name: i});
	}

	return final;
}

const sharedDef = {
	label: 'ACCOUNT',
	component: {
		type: 'ref',
		configuration: {
			collection: Collections.InvoicingAccounts,
			clearValue: null,
			search: {key: '/name', label: 'NAME'},
			display: {key: '/name', label: 'ACCOUNT'},
			table: {
				tableColumns: [
					{key: '/name', label: 'NAME'},
					{key: '/id', label: 'ID'}
				]
			}
		}
	}
}

export const ACCOUNT = {
	properties: {
		account: {type: 'string'}
	},
	definitions: {
		account: sharedDef
	} as any,
	filterDefinitions: {
		account: {
			...sharedDef,
			filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
			filterValuePipeArguments: {
				0: id => [`${Collections.InvoicingAccounts}/${id}`],
				1: it => it[0].name
			},
		}
	} as any
};

export const FISCAL_YEAR = {
	properties: {
		fiscalYear: {type: 'number'}
	},
	definitions: {
		fiscalYear: {
			label: 'FISCAL_YEAR',
			component: {
				type: 'select',
				configuration: {
					dataSet: years()
				}
			}
		}
	}
}