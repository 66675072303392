import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import {YES_NO_PIPE} from '../shared/yes-no-pipe';
import {ACCOUNT} from './shared/account.const';

export const INVOICE_TYPES_MODULE: Module = {
  id: Collections.InvoiceTypes,
  name: 'TYPES',
  layout: {
		editTitleKey: 'name',
    sort: {
			active: 'name',
			direction: 'asc'
		},
		filterModule: {
			persist: true,
			clearFilters: {},
			value: [],
			schema: {
				properties: {
					...ACCOUNT.properties
				}
			},
			definitions: {
				...ACCOUNT.filterDefinitions
			}
		},
    instance: {
      segments: [
				{
					title: 'GENERAL',
					fields: [
						'/name',
						'/description',
					]
				},
				{
					title: 'CONFIGURATION',
					fields: [
						'/account',
						'/templates',
						'/expectsPayment'
					]
				}
			]
    },
    table: {
      tableColumns: [
        {key: '/name', label: 'NAME'},
        {key: '/description', label: 'DESCRIPTION'},
				{key: '/expectsPayment', label: 'EXPECTS_PAYMENT', ...YES_NO_PIPE}
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string'},
			description: {type: 'string'},
			templates: {type: 'array'},
			expectsPayment: {type: 'boolean', default: true},
			...ACCOUNT.properties
    },
		required: [
			'name',
			'account'
		]
  },
  definitions: {
    name: {label: 'NAME'},
    description: {
			label: 'DESCRIPTION',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
		templates: {
			label: 'TEMPLATES',
			component: {
				type: 'ref',
				configuration: {
					multiple: true,
					collection: Collections.InvoiceTemplates,
					display: {
						label: 'TEMPLATES',
						key: '/name'
					},
					table: {
						columns: [
							{key: '/name', label: 'NAME'},
							{key: '/description', label: 'DESCRIPTION'},
						]
					}
				}
			}
		},
		expectsPayment: {label: 'EXPECTS_PAYMENT'},
		...ACCOUNT.definitions
  },
	metadata: {
		docIdPrefix: 'int'
	}
};
