import {Collections} from '@definitions';
import {NavigationItemWithActive} from '../app/shared/interfaces/navigation-item-with-active.interface';

/**
 * Configuration that is consistent across environments
 */
export const STATIC_CONFIG = {
  displayName: 'Jaspero Team',
  elements: {

    /**
     * This is removed from the components selector
     * when registering it as an element
     */
    componentPrefix: 'jms-',

    /**
     * This is added as the suffix instead
     *
     * @example
     * A component with the selector jms-table
     * is registered as an element with the selector
     * jms-e-table
     */
    selectorPrefix: 'jms-e-',
  },
  login: {
    email: true,
    google: true,
    facebook: false
  },

  /**
   * Controls which roles can access the dashboard
   * if empty any user with a role can access
   */
  dashboardRoles: [],

  /**
   * We use this to redirect all unauthenticated users
   */
  loginRoute: ['/login'],
  /**
   * Used for redirecting all authenticated users
   * visiting pages for unauthenticated users
   */
  dashboardRoute: ['/dashboard'],
  navigation: {
    items: [
      {
        icon: 'dashboard',
        label: 'DASHBOARD',
        type: 'link',
        value: '/dashboard'
      },
      {
        icon: 'event',
        label: 'EVENTS',
        type: 'link',
        value: '/m/events'
      },
      {
        icon: 'supervised_user_circle',
        label: 'TEAM',
        type: 'link',
        value: '/m/users'
      },
      {
        children: [
          {
            icon: 'attach_money',
            label: 'INVOICES',
            type: 'link',
            value: '/m/invoices'
          },
          {
            icon: 'label',
            label: 'TYPES',
            type: 'link',
            value: '/m/invoice-types'
          },
          {
            icon: 'category',
            label: 'INVOICE_ITEMS',
            type: 'link',
            value: '/m/invoice-items'
          },
          {
            icon: 'perm_contact_calendar',
            label: 'INVOICING_CONTACTS',
            type: 'link',
            value: '/m/invoicing-contacts'
          },
          {
            icon: 'account_balance_wallet',
            label: 'INVOICING_ACCOUNTS',
            type: 'link',
            value: '/m/invoicing-accounts'
          },
          {
            icon: 'article',
            label: 'INVOICE_TEMPLATES',
            type: 'link',
            value: '/m/invoice-templates'
          },
          {
            icon: 'sell',
            label: 'TAGS',
            type: 'link',
            value: '/m/invoice-tags'
          },
          {
            icon: 'description',
            label: 'DOCUMENTS',
            type: 'link',
            value: '/m/accounting-documents'
          },
        ],
        icon: 'receipt',
        label: 'ACCOUNTING',
        type: 'expandable',
        authorized: ['admin']
      },
      {
        children: [
          {
            icon: 'description',
            label: 'DOCUMENTS',
            type: 'link',
            value: '/m/documents'
          },
          {
            icon: 'send',
            label: 'INVITES',
            type: 'link',
            value: '/m/user-invites'
          },
          {
            icon: 'edit_calendar',
            label: 'EVENT_TYPES',
            type: 'link',
            value: '/m/event-types'
          },
          {
            icon: 'newspaper',
            label: 'DOCUMENT_TEMPLATES',
            type: 'link',
            value: '/m/document-templates'
          },
          {
            icon: 'work',
            label: 'POSITIONS',
            type: 'link',
            value: '/m/positions'
          }
        ],
        icon: 'manage_accounts',
        label: 'HR',
        type: 'expandable',
        authorized: ['admin']
      },
      {
        children: [
          {
            icon: 'rss_feed',
            label: 'Posts',
            type: 'link',
            value: '/m/posts',
            hasPermission: Collections.Posts
          },
          {
            icon: 'rss_feed',
            label: 'Job Openings',
            type: 'link',
            value: '/m/job-openings',
            hasPermission: Collections.JobOpenings
          },
          {
            icon: 'rss_feed',
            label: 'Job Applications',
            type: 'link',
            value: '/m/job-applications',
            hasPermission: Collections.JobApplications
          }
        ],
        icon: 'rss_feed',
        label: 'Website',
        type: 'expandable'
      },
      {
        children: [
          {
            icon: 'work',
            label: 'COMPANIES',
            type: 'link',
            value: '/m/companies'
          },
          {
            icon: 'email',
            label: 'EMAIL_TEMPLATES',
            type: 'link',
            value: '/m/automatic-emails'
          },
          {
            icon: 'send',
            label: 'SENT_EMAILS',
            type: 'link',
            value: '/m/sent-emails'
          },
          // {
          //   icon: 'tune',
          //   label: 'SETTINGS',
          //   type: 'link',
          //   value: '/m/settings/main'
          // }
        ],
        icon: 'widgets',
        label: 'GENERAL',
        type: 'expandable',
        authorized: ['admin']
      },
    ] as NavigationItemWithActive[]
  },
  shellyAuthKey: 'MTMxZmZldWlk8778E7FCF9C901213B8BD17F87D0D27688B4CB1C5102B485E2DFF2506EC4010EE3EC3F13E68602F1'
};
