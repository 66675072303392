import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';

export const SETTINGS_MODULE: Module = {
  id: Collections.Settings,
  name: 'SETTINGS',
  layout: {
		directLink: 'main',
    instance: {
      segments: []
    }
  },
  schema: {
    properties: {}
  },
  definitions: {},
  metadata: {
    attachedFiles: {
      containes: false
    }
  }
};
