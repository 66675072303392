import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {CREATED_ON} from '../shared/created-on';

export const EVENTS_MODULE: Module = {
	id: Collections.Events,
	name: 'EVENTS',
	layout: {
		sort: CREATED_ON.sort,
		overview: {
			showViewSelector: true,
			defaultView: 'jms-e-calendar',
			views: [
				{
					selector: 'jms-e-calendar',
					title: 'Calendar',
					icon: 'event'
				},
				{
					selector: 'table',
					title: 'Table',
					icon: 'table_chart'
				}
			],
			toolbar: [JSX(<jms-e-event-add/>)]
		},
		table: {
			tableColumns: [
				CREATED_ON.column(),
				{
					key: '/event',
					label: 'EVENT',
					populate: {
						collection: Collections.EventTypes
					}
				},
				{
					key: '/startTime',
					label: 'START_TIME',
					pipe: [PipeType.Date],
				},
				{
					key: '/endTime',
					label: 'END_TIME',
					pipe: [PipeType.Date],
				},
				{
					key: '/owner',
					label: 'OWNER',
					populate: {
						collection: Collections.Users
					}
				},
				{
					key: '/members',
					label: 'MEMBERS',
					populate: {
						collection: Collections.Users
					}
				}
			]
		},
	},
	schema: {
		properties: {
			id: {type: 'string'},
			startTime: {type: 'number'},
			endTime: {type: 'number'},
			name: {type: 'string'},
			description: {type: 'string'},
			event: {type: 'string'},
			color: {type: 'string'},
			public: {type: 'boolean'},
			members: {type: 'array'},
			owner: {type: 'string'},
			...CREATED_ON.property
		},
		required: [
			'startTime',
			'endTime',
			'event',
			'owner'
		]
	},
	definitions: {
		event: {
			label: 'EVENT_TYPE',
			component: {
				type: 'select',
				configuration: {
					populate: {
						collection: Collections.EventTypes
					}
				}
			}
		},
		startTime: {
			label: 'START_TIME',
			component: {
        type: 'date',
        configuration: {
          includeTime: true,
          labelHours: 'HOURS',
          labelMinutes: 'MINUTES',
          format: 'number'
        }
      }
		},
		endTime: {
			label: 'END_TIME',
			component: {
        type: 'date',
        configuration: {
          includeTime: true,
          labelHours: 'HOURS',
          labelMinutes: 'MINUTES',
          format: 'number'
        }
      }
		},
		owner: {
			label: 'OWNER',
			component: {
				type: 'ref',
				configuration: {
					collection: Collections.Users,
					display: {
						key: '/name',
						label: 'OWNER'
					},
					table: {
						tableColumns: [
							{key: '/name', label: 'NAME'}
						]
					}
				}
			}
		},
		members: {
			label: 'MEMBERS',
			component: {
				type: 'ref',
				configuration: {
					collection: Collections.Users,
					multiple: true,
					display: {
						key: '/name',
						label: 'USER'
					},
					table: {
						tableColumns: [
							{key: '/name', label: 'NAME'}
						]
					}
				}
			}
		},
		...CREATED_ON.definition()
	}
}