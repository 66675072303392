import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';

export const INVOICE_ITEMS_MODULE: Module = {
  id: Collections.InvoiceItems,
  name: 'INVOICE_ITEMS',
  layout: {
    sort: {
			active: 'en.name',
			direction: 'asc'
		},
    instance: {
      segments: [
				{
					title: 'ENGLISH',
					fields: [
						'/en/name',
					]
				},
				{
					title: 'CROATIAN',
					fields: [
						'/hr/name'
					]
				}
			]
    },
    table: {
      tableColumns: [
        {key: '/en/name', label: 'ENGLISH'},
        {key: '/hr/name', label: 'CROATIAN'},
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      en: {
				type: 'object',
				properties: {
					name: {type: 'string'}
				}
			},
			hr: {
				type: 'object',
				properties: {
					name: {type: 'string'}
				}
			}
    }
  },
  definitions: {
    'en/name': {label: 'NAME'},
    'hr/name': {label: 'NAME'}
  },
	metadata: {
		docIdPrefix: 'ini'
	}
};
