import {PipeType} from '../../enums/pipe-type.enum';
import {Module} from '../../interfaces/module.interface';
import {CREATED_ON} from '../shared/created-on';
import JSX from '../../jsx.compiler';
import {Collections} from '../../interfaces/collections';

export const INVOICING_CONTACTS_MODULE: Module = {
  id: Collections.InvoicingContacts,
  name: 'INVOICING_CONTACTS',
  layout: {
    editTitleKey: 'name',
    sort: CREATED_ON.sort,
    instance: {
      segments: [
				{
					title: 'GENERAL',
					fields: [
						'/name',
            '/contact',
            '/oib',
						'/address'
					]
				}
			],
      actions: [
        {
          value: it => JSX(<jms-e-notes data-id={it.id}/>)
        }
      ]
    },
    table: {
      tableColumns: [
        {key: '/name', label: 'NAME'},
        {
          key: '/contact',
          label: 'CONTACT',
          pipe: [PipeType.Custom, PipeType.Sanitize],
          pipeArguments: {
            0: v => `<a href="mailto:${v}" class="link" target="blank">${v}</a>`
          }
        },
        {key: '/oib', label: 'OIB'},
        {key: '/address', label: 'ADDRESS', pipe: [PipeType.Sanitize]},
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string',},
      address: {type: 'string'},
			contact: {type: 'string'},
      oib: {type: 'string'},
      ...CREATED_ON.property
    },
		required: [
			'name'
		]
  },
  definitions: {
    name: {label: 'NAME'},
    label: {label: 'OIB'},
		address: {
			label: 'ADDRESS',
			component: {
				type: 'tinymce'
			}
		},
		contact: {
			label: 'CONTACT',
			component: {
				type: 'input',
        configuration: {
          type: 'email'
        }
			}
		},
    ...CREATED_ON.definition()
  },
  metadata: {
		docIdPrefix: 'inc',
    subCollections: [
			{name: 'notes'}
		]
	}
};
