import {EVENT_TYPES_MODULE} from './calendar/event-types.module';
import {EVENTS_MODULE} from './calendar/events.module';
import {COMPANIES_MODULE} from './companies.module';
import {INVOICE_ITEMS_MODULE} from './invoicing/invoice-items.module';
import {INVOICE_TEMPLATES_MODULE} from './invoicing/invoice-templates.module';
import {INVOICES_MODULE} from './invoicing/invoices.module';
import {INVOICING_ACCOUNTS_MODULE} from './invoicing/invoicing-accounts.module';
import {INVOICING_CONTACTS_MODULE} from './invoicing/invoicing-contacts.module';
import {POSITIONS_MODULE} from './positions.module';
import {ROLES_MODULE} from './roles.module';
import {USER_HISTORY_MODULE} from './user-history.module';
import {USER_INVITES_MODULE} from './user-invites.module';
import {USERS_MODULE} from './users.module';
import {USER_EMPLOYMENT_INFORMATION} from './user-employment-information.module';
import {DOCUMENT_TEMPLATES_MODULE} from './document-templates.module';
import {DOCUMENTS_MODULE} from './documents.module';
import {AUTOMATIC_EMAILS_MODULE} from './emails/automatic-emails.module';
import {SENT_EMAILS_MODULE} from './emails/sent-emails.module';
import {INVOICE_TAGS_MODULE} from './invoicing/invoice-tags.module';
import {INVOICE_TYPES_MODULE} from './invoicing/invoice-types.module';
import {STORAGE_MODULE} from './storage.module';
import {SETTINGS_MODULE} from './settings.module';
import {ROLE_HISTORY_MODULE} from './role-history.module';
import {ACCOUNTING_DOCUMENTS_MODULE} from './accounting-documents.module';
import {ACCOUNTING_DOCUMENTS_INVOCATIONS_MODULE} from './accounting-documents-invocations.module';
import {POSTS_MODULE} from './website/posts.module';
import {JOB_APPLICATIONS_MODULE} from './website/job-applications.module';
import {JOB_OPENINGS_MODULE} from './website/job-openings.module';

/**
 * Schemas for all of the modules
 */
export const MODULES = [

  COMPANIES_MODULE,
  SETTINGS_MODULE,

  AUTOMATIC_EMAILS_MODULE,
  SENT_EMAILS_MODULE,

  /**
   * Users
   */
  USERS_MODULE,
  ROLES_MODULE,
  USER_INVITES_MODULE,
  USER_EMPLOYMENT_INFORMATION,

  /**
   * HR
   */
  POSITIONS_MODULE,
  DOCUMENT_TEMPLATES_MODULE,
  DOCUMENTS_MODULE,

  /**
   * Calendar
   */
  EVENT_TYPES_MODULE,
  EVENTS_MODULE,

  /**
   * Invoicing
   */
  INVOICING_ACCOUNTS_MODULE,
  INVOICE_TYPES_MODULE,
  INVOICING_CONTACTS_MODULE,
  INVOICE_TEMPLATES_MODULE,
  INVOICE_ITEMS_MODULE,
  INVOICE_TAGS_MODULE,
  INVOICES_MODULE,
  ACCOUNTING_DOCUMENTS_MODULE,
  ACCOUNTING_DOCUMENTS_INVOCATIONS_MODULE,

  /**
   * Website
   */
  POSTS_MODULE,
  JOB_OPENINGS_MODULE,
  JOB_APPLICATIONS_MODULE,

  USER_HISTORY_MODULE,
  ROLE_HISTORY_MODULE,
  STORAGE_MODULE,

  AUTOMATIC_EMAILS_MODULE,
  SENT_EMAILS_MODULE,
];
