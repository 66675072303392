import {SHARED_CONFIG} from '../../consts/shared-config.const';
import {FilterMethod} from '../../enums/filter-method.enum';
import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {CREATED_ON} from '../shared/created-on';
import {ACCOUNT, FISCAL_YEAR} from './shared/account.const';

const CONTACT = {
	properties: {
		contact: {type: 'string'}
	},
	definitions: {
		contact: {
			label: 'CONTACT',
			component: {
				type: 'ref',
				configuration: {
					collection: Collections.InvoicingContacts,
					clearValue: null,
					search: {key: '/name', label: 'NAME'},
					display: {key: '/name', label: 'CONTACT'},
					table: {
						tableColumns: [
							{key: '/name', label: 'NAME'},
							{key: '/id', label: 'ID'}
						]
					}
				}
			}
		}
	}
};

const TYPE = {
	properties: {
		type: {type: 'string'}
	},
	definitions: {
		type: {
			label: 'TYPE',
			component: {
				type: 'select',
				configuration: {
					populate: {
						collection: Collections.InvoiceTypes,
						dependency: {
							key: '/account',
							method: v => ({
								collection: Collections.InvoiceTypes,
								orderBy: {active: 'name', direction: 'asc'},
								filter: {key: 'account', operator: '==', value: v}
							})
						}
					}
				}
			}
		}
	}
};

const CURRENCY = {
	properties: {
		currency: {type: 'string'}
	},
	definitions: {
		label: 'CURRENCY',
		currency: {
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'GBP', value: 'GBP'},
						{name: 'EUR', value: 'EUR'},
						{name: 'HRK', value: 'HRK'},
						{name: 'USD', value: 'USD'},
					]
				}
			}
		}
	}
};

export const INVOICES_MODULE: Module = {
	id: Collections.Invoices,
	name: 'INVOICES',
	layout: {
		editTitleKey: 'number',
		sort: {
			active: 'number',
			direction: 'desc'
		},
		filterModule: {
			persist: true,
			clearFilters: {},
			value: [{
				key: 'fiscalYear',
				operator: '==',
				value: new Date().getFullYear(),
				label: 'FISCAL_YEAR'
			}],
			schema: {
				properties: {
					number: {type: 'number'},
					tags: {type: 'array'},
					status: {type: 'string'},
					...FISCAL_YEAR.properties,
					...ACCOUNT.properties,
					...CONTACT.properties,
					...CURRENCY.properties,
					...TYPE.properties
				}
			},
			definitions: {
				number: {label: 'NUMBER'},
				contact: {
					...CONTACT.definitions.contact,
					filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
					filterValuePipeArguments: {
						0: id => [`${Collections.InvoicingContacts}/${id}`],
						1: it => it[0].name
					},
				},
				type: {
					...TYPE.definitions.type,
					filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
					filterValuePipeArguments: {
						0: id => [`${Collections.InvoiceTypes}/${id}`],
						1: it => it[0].name
					},
				},
				tags: {
					label: 'TAGS',
					filterMethod: FilterMethod.ArrayContainsAny,
					filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
					filterValuePipeArguments: {
						0: values => values.map(val => `${Collections.InvoiceTags}/${val}`),
						1: it => it.map(i => i.name).join(', ')
					},
					component: {
						type: 'select',
						configuration: {
							multiple: true,
							populate: {
								collection: Collections.InvoiceTags
							}
						}
					}
				},
				status: {
					label: 'STATUS',
					component: {
						type: 'select',
						configuration: {
							dataSet: [
								{name: 'PENDING', value: 'pending'},
								{name: 'PAID', value: 'paid'}
							]
						}
					}
				},
				...FISCAL_YEAR.definitions,
				...CURRENCY.definitions,
				...ACCOUNT.filterDefinitions
			} as any,
			segments: [{
				type: 'empty',
				fields: [
					'/number',
					'/status',
					'/contact',
					'/currency',
					'/fiscalYear',
					'/account',
					{
						field: '/type',
						deps: ['/account'],
						action: {
							type: 'show',
							eval: v => v.account
						}
					},
				]
			}]
		},
		instance: {
			segments: [
				{
					type: 'tabs',
					configuration: {
						tabs: [
							{
								title: 'GENERAL',
								fields: [
									'/number',
									'/contact',
									'/account',
									{
										field: '/type',
										deps: ['/account'],
										action: {
											type: 'show',
											eval: v => v.account
										}
									},
									'/currency',
									'/taxRate'
								]
							},
							{
								title: 'DATES',
								fields: [
									'/dueDate',
									'/issueDate',
									'/fiscalYear',
									'/paidOn'
								]
							},
							{
								title: 'MISC',
								fields: [
									'/tags',
									'/status',
									'/total',
									'/baseTotal',
									'/notes'
								]
							}
						]
					}
				},
				{
					title: 'ITEMS',
					array: '/items',
					arrayConfiguration: {
						reverse: false
					},
					fields: [
						'/name',
						'/count',
						'/value'
					]
				}
			],
			actions: [
				{
					value: it => JSX(<jms-e-invoices-download id={it.id} number={it.data.number} type={it.data.type} />)
				},
				{
					value: it => JSX(<jms-e-notes data-id={it.id} />)
				}
			]
		},
		table: {
			tableColumns: [
				{key: '/number', label: 'NUMBER', sortable: true},
				{key: '/type', label: 'TYPE', populate: {collection: Collections.InvoiceTypes}},
				{
					key: '/id',
					label: 'PAID_ON',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: (v, i) => JSX(<jms-e-invoices-paid-on paid={i.paidOn} id={v} />)
					}
				},
				{key: '/fiscalYear', label: 'FISCAL_YEAR'},
				{key: '/dueDate', label: 'DUE_DATE', pipe: [PipeType.Date], sortable: true},
				{key: '/issueDate', label: 'ISSUE_DATE', pipe: [PipeType.Date]},
				{key: '/contact', label: 'CONTACT', populate: {collection: Collections.InvoicingContacts}},
				{key: '/account', label: 'ACCOUNT', populate: {collection: Collections.InvoicingAccounts}},
				{key: '/currency', label: 'CURRENCY'},
				{key: '/taxRate', label: 'TAX_RATE', pipe: [PipeType.Percent]},
				{
					key: '/total',
					label: 'TOTAL',
					pipe: [PipeType.Currency],
					pipeArguments: {
						0: [(v, r) => r.currency]
					}
				},
				{
					key: '/tags',
					label: 'TAGS',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: v => JSX(<jms-e-tags-column tags={v} />)
					}
				}
			]
		},
		overview: {
			defaultView: JSX(<div>
				<jms-e-invoice-scorecard />
				<jms-e-table />
			</div>)
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			number: {type: 'number'},
			issueDate: {type: 'number'},
			dueDate: {type: 'number'},
			updatedOn: {type: 'number'},
			paidOn: {type: 'number', default: null},
			taxRate: {type: 'number'},
			contact: {type: 'string'},
			total: {type: 'number'},
			baseTotal: {type: 'number'},
			tags: {type: 'array'},
			counted: {type: 'boolean'},
			status: {type: 'string', default: 'pending'},
			notes: {type: 'string'},

			items: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						name: {type: 'string'},
						count: {type: 'number', default: 1},
						value: {type: 'number'}
					}
				}
			},
			...FISCAL_YEAR.properties,
			...CONTACT.properties,
			...ACCOUNT.properties,
			...CURRENCY.properties,
			...TYPE.properties,
			...CREATED_ON.property,
		},
		required: [
			'currency',
			'type',
			'account',
			'issueDate',
		]
	},
	definitions: {
		number: {label: 'NUMBER', hint: 'Recives the next number is sequence if left empty.'},
		'items/name': {
			label: 'NAME',
			columnsDesktop: 4,
			component: {
				type: 'select',
				configuration: {
					populate: {
						collection: Collections.InvoiceItems,
						nameKey: 'en.name',
						orderBy: {active: 'en.name', direction: 'asc'}
					}
				}
			}
		},
		'items/count': {label: 'COUNT', columnsDesktop: 4},
		'items/value': {label: 'VALUE', columnsDesktop: 4},
		fiscalYear: {
			...FISCAL_YEAR.definitions.fiscalYear,
			formatOnLoad: v => v || new Date().getFullYear()
		},
		issueDate: {
			label: 'ISSUE_DATE',
			formatOnLoad: v => v || Date.now(),
			component: {
				type: 'date',
				configuration: {
					includeTime: true,
					labelHours: 'HOURS',
					labelMinutes: 'MINUTES',
					format: 'number'
				}
			}
		},
		dueDate: {
			label: 'DUE_DATE',
			formatOnLoad: v => v || (Date.now() + (1000 * 60 * 60 * 24 * 7)),
			component: {
				type: 'date',
				configuration: {
					includeTime: true,
					labelHours: 'HOURS',
					labelMinutes: 'MINUTES',
					format: 'number'
				}
			}
		},
		paidOn: {
			label: 'PAID_ON',
			component: {
				type: 'date',
				configuration: {
					includeTime: true,
					labelHours: 'HOURS',
					labelMinutes: 'MINUTES',
					format: 'number'
				}
			}
		},
		taxRate: {
			label: 'TAX_RATE',
			formatOnLoad: v => (v || v === 0) ? v * 100 : null,
			formatOnSave: v => (v || v === 0) ? v / 100 : null,
			hint: 'Uses the account tax rate if left empty.',
			component: {
				type: 'input',
				configuration: {
					type: 'number',
					suffix: '%'
				}
			}
		},
		tags: {
			label: 'TAGS',
			component: {
				type: 'select',
				configuration: {
					multiple: true,
					populate: {
						collection: Collections.InvoiceTags
					}
				}
			}
		},
		notes: {
			label: 'NOTES',
			component: {
				type: 'textarea'
			}
		},
		updatedOn: {
			formatOnSave: () => Date.now()
		},
		total: {
			type: 'TOTAL'
		},
		baseTotal: {
			label: 'BASE_TOTAL',
			component: {
				type: 'input',
				configuration: {
					suffix: SHARED_CONFIG.baseCurrency
				}
			}
		},
		status: {
			label: 'STATUS',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'PENDING', value: 'pending'},
						{name: 'PAID', value: 'paid'}
					]
				}
			}
		},
		...CONTACT.definitions,
		...ACCOUNT.definitions,
		...CURRENCY.definitions,
		...TYPE.definitions,
		...CREATED_ON.definition()
	},
	metadata: {
		subCollections: [
			{name: 'notes'}
		]
	}
};
