import {DocumentTypes} from '../enums/document-types.enum';
import {PipeType} from '../enums/pipe-type.enum';
import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

export const DOCUMENT_TEMPLATES_MODULE: Module = {
  id: Collections.DocumentTemplates,
  name: 'Document Templates',
  layout: {
		editTitleKey: 'name',
    sort: {
      active: 'name',
      direction: 'asc'
    },
    instance: {
      segments: [
				{
					title: 'GENERAL',
					fields: [
						'/name',
						'/type',
						'/template',
						'/companies'
					]
				},
				{
					title: 'FORMATTING',
					fields: [
						'/language',
						'/currencyFormat',
						'/dateFormat'
					]
				}
			]
    },
    table: {
      tableColumns: [
        {key: '/name', label: 'NAME'},
				{
					key: '/type',
					label: 'TYPE',
					pipe: [PipeType.Transloco]
				},
				{key: '/language', label: 'LANGUAGE'},			
				{key: '/companies', label: 'COMPANIES', populate: {collection: Collections.Companies}},	
				{
					key: '/template',
					label: 'VIEW',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: v => `<a class="link" href="${v}">View</link>`
					}
				},
      ]
    }
  },
  schema: {
    properties: {
			id: {type: 'string'},
      name: {type: 'string',},
      type: {type: 'string'},
			template: {type: 'string'},
			currencyFormat: {type: 'string'},
			language: {type: 'string'},
			dateFormat: {type: 'string'},
			companies: {type: 'array'},
      ...CREATED_ON.property
    },
		required: [
			'name',
			'template'
		]
  },
  definitions: {
		name: {label: 'NAME'},
		type: {
			label: 'TYPE',
			component: {
				type: 'select',
				configuration: {
					dataSet: Object.values(DocumentTypes)
						.map(it => ({name: it, value: it}))
				}
			}
		},
		currencyFormat: {
			label: "CURRENCY_FORMAT",
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'US', value: 'en-US'},
						{name: 'DE', value: 'de-DE'},
						{name: 'HR', value: 'hr-HR'},
					]
				}
			}
		},
		dateFormat: {
			label: 'DATE_FORMAT',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'US', value: 'en'},
						{name: 'DE', value: 'de'},
						{name: 'HR', value: 'hr'},
					]
				}
			}
		},
		language: {
			label: 'LANGUAGE',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'ENGLISH', value: 'en'},
						{name: 'CROATIAN', value: 'hr'}
					]
				}
			}
		},
		template: {
			label: 'TEMPLATE',
			component: {
				type: 'file',
				configuration: {
					filePrefix: `/documents/templates/`,
					uploadMethods: [{
						id: 'file-manager',
						label: 'FILE_MANAGER.TITLE',
						component: JSX(<jms-e-file-manager-select />),
						configuration: {
							hidePath: false,
							hideFolders: false,
							allowUpload: true,
							minPath: `/documents/templates`,
							route: `/documents/templates`
						}
					}]
				}
			}
		},
		companies: {
			label: 'COMPANIES',
			component: {
				type: 'select',
				configuration: {
					multiple: true,
					populate: {
						collection: Collections.Companies
					}
				}
			}
		},
    ...CREATED_ON.definition()
  },
	metadata: {
		docIdPrefix: 'dt'
	}
}