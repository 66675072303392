export class Collections {
  static Users = 'users';
  static Roles = 'roles';
	static UserInvites = 'user-invites';
	static Companies = 'companies';
	static Positions = 'positions';
	static Settings = 'settings';

	static AutomaticEmails = 'automatic-emails';
	static SentEmails = 'sent-emails';

	/**
	 * HR
	 */
	static DocumentTemplates = 'document-templates';
	static Documents = 'documents';

	/**
	 * Events
	 */
	static Events = 'events';
	static EventTypes = 'event-types';

	/**
	 * Accounting
	 */
	static Invoices = 'invoices';
	static InvoiceItems = 'invoice-items';
	static InvoiceTypes = 'invoice-types';
	static InvoiceTags = 'invoice-tags';
	static InvoiceTemplates = 'invoice-templates';
	static InvoicingAccounts = 'invoicing-accounts';
	static InvoicingContacts = 'invoicing-contacts';
	static AccountingDocuments = 'accounting-documents';

	/**
	 * Website
	 */
	static Posts = 'posts';
	static JobOpenings = 'job-openings';
	static JobApplications = 'job-applications';

	/**
	 * Subcollections
	 */
	static History = 'history';
	static Notes = 'notes';
	static Invocations = 'invocations';

	static HistorySub(collection: string, docId?: string) {
		return `${collection}/${docId || '{docId}'}/${Collections.History}`;
	}

	static InvocationSub(collection: string, docId?: string) {
		return `${collection}/${docId || '{docId}'}/${Collections.Invocations}`;
	}

	static get UserHistory() {
		return Collections.HistorySub('users');
	}

	static UserHistorySub(docId: string) {
		return Collections.HistorySub('users', docId);
	}

	static Info = 'info';

	static InfoSub(collection: string, docId?: string) {
		return `${collection}/${docId || '{{docId}}'}/${Collections.Info}`;
	}

	static get UserInfo() {
		return Collections.InfoSub('users');
	}

	static get RoleHistory() {
		return Collections.HistorySub('roles');
	}

	static RoleHistorySub(docId: string) {
		return Collections.HistorySub('roles', docId);
	}

	static AccountingDocumentsInvocations(id: string) {
		return [Collections.AccountingDocuments, id, 'invocations'].join('/');
	}

	static AccountingDocumentsInvocationsSub() {
		return Collections.InvocationSub(Collections.AccountingDocuments);
	}
}
