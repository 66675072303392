/**
 * Shared public environment config
 */
export const ENV_CONFIG = {
  firebase: {
    projectId: 'jaspero-site',
    appId: '1:670493787162:web:d2338c15f9d04d7a',
    databaseURL: 'https://jaspero-site.firebaseio.com',
    storageBucket: 'jaspero-site.appspot.com',
    locationId: 'us-central',
    apiKey: 'AIzaSyAWZ0dIUsRJqaHmHa0VYQYxQcn1rIh8Kfs',
    authDomain: 'jaspero-site.firebaseapp.com',
    messagingSenderId: '670493787162'
  }
};
