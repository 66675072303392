import {Module} from '../../interfaces/module.interface';
import {PipeType} from '../../enums/pipe-type.enum';
import JSX from '../../jsx.compiler';

export const JOB_OPENINGS_MODULE: Module = {
  id: 'job-openings',
  name: 'Job Openings',
  layout: {
    editTitleKey: 'title',
    sort: {
      active: 'createdOn',
      direction: 'desc'
    },
    instance: {
      segments: [
        {
          title: 'GENERAL',
          type: 'card',
          fields: [
            '/active',
            '/title',
            '/createdOn',
            '/location',
            '/description'
          ],
          columnsDesktop: 6
        }
      ]
    },
    table: {
      tableColumns: [
        {
          key: '/createdOn',
          label: 'Date',
          pipe: [PipeType.Date],
          sortable: true
        },
        {key: '/title', label: 'Title'},
        {
          key: '/active',
          label: 'Active',
          control: true
        }
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      title: {type: 'string'},
      description: {type: 'string'},
      createdOn: {type: 'number'},
      active: {type: 'boolean'},
      location: {type: 'string'}
    },
    required: ['title']
  },
  definitions: {
    title: {label: 'Title'},
    description: {
      label: 'Description',
      component: {
        type: 'tinymce'
      }
    },
    createdOn: {
      formatOnLoad: '(value) => value || Date.now()',
      component: {
        type: 'date',
        configuration: {
          includeTime: false,
          format: 'number'
        }
      }
    },
    location: {
      label: 'Location',
      component: {
        type: 'select',
        configuration: {
          multiple: true,
          dataSet: [
            {value: 'Osijek', name: 'Osijek'},
            {value: 'Remote', name: 'Remote'}
          ]
        }
      }
    }
  }
};
