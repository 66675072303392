import {FilterMethod} from '../enums/filter-method.enum';
import {PipeType} from '../enums/pipe-type.enum';
import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

const COMPANY = {
	properties: {
		company: {type: 'string'}
	},
	definitions: {
		company: {
			label: 'COMPANY',
			component: {
				type: 'ref',
				configuration: {
					collection: Collections.Companies,
					clearValue: null,
					search: {key: '/name', label: 'NAME'},
					display: {key: '/name', label: 'COMPANY'},
					table: {
						tableColumns: [
							{key: '/name', label: 'NAME'},
							{key: '/oib', label: 'OIB'}
						]
					}
				}
			}
		}
	} as any
};

const TEMPLATE = {
	properties: {
		template: {type: 'string'}
	},
	definitions: {
		template: {
			label: 'TEMPLATE',
			contact: {
				component: {
					type: 'ref',
					configuration: {
						collection: Collections.DocumentTemplates,
						clearValue: null,
						search: {key: '/name', label: 'NAME'},
						display: {key: '/name', label: 'TEMPLATE'},
						table: {
							tableColumns: [
								{key: '/name', label: 'NAME'},
								{key: '/description', label: 'DESCRIPTION'}
							]
						}
					}
				}
			}
		}
	}
};

export const DOCUMENTS_MODULE: Module = {
	id: Collections.Documents,
	name: 'DOCUMENTS',
	schema: {
		properties: {
			id: {type: 'string'},
			name: {type: 'string'},
			template: {type: 'string'},
			members: {type: 'array'},
			company: {type: 'string'},
			data: {type: 'object'},
			...CREATED_ON.property
		}
	},
	layout: {
		editTitleKey: 'name',
		sort: CREATED_ON.sort,
		filterModule: {
			persist: true,
			clearFilters: {},
			schema: {
				properties: {
					start: {type: 'string'},
          end: {type: 'string'},
					...COMPANY.properties,
					...TEMPLATE.properties
				}
			},
			definitions: {
				start: {
          label: 'START_DATE',
          columnsDesktop: 6,
          filterKey: 'startDate',
          filterMethod: FilterMethod.GreaterThen,
          filterValuePipe: [PipeType.Date],
          filterLabel: 'START_DATE',
          component: {
            type: 'date',
            configuration: {
              format: 'number'
            }
          }
        },
        end: {
          label: 'END_DATE',
          columnsDesktop: 6,
          filterMethod: FilterMethod.LessThen,
          filterKey: 'startDate',
          filterValuePipe: [PipeType.Date],
          filterLabel: 'END_DATE',
          component: {
            type: 'date',
            configuration: {
              format: 'number'
            }
          }
        },
				company: {
					...COMPANY.definitions.company,
					filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
					filterValuePipeArguments: {
						0: id => [`${Collections.Companies}/${id}`],
						1: it => it[0].name
					},
				},
				template: {
					...TEMPLATE.definitions.template,
					filterValuePipe: [PipeType.GetDocuments, PipeType.Custom],
					filterValuePipeArguments: {
						0: id => [`${Collections.DocumentTemplates}/${id}`],
						1: it => it[0].name
					},
				},
			} as any
		},
		instance: {
			actions: [
				{
					value: it => JSX(<jms-e-document-download id={it.id} />)
				},
				{
          value: it => JSX(<jms-e-notes data-id={it.id}/>),
          authorization: ['admin']
        }
			],
			segments: [{
				title: 'GENERAL',
				fields: [
					'/name',
					'/template',
					'/company',
					'/members'
				]
			}]
		},
		table: {
			hideImport: true,
			tableColumns: [
				CREATED_ON.column(),
				{key: '/name', label: 'NAME'},
				{
					key: '/template',
					label: 'TEMPLATE',
					populate: {
						collection: Collections.DocumentTemplates,
					}
				},
				{
					key: '/company',
					label: 'COMPANY',
					populate: {
						collection: Collections.Companies
					}
				}
			]
		}
	},
	definitions: {
		name: {label: 'NAME'},
		members: {
			label: 'MEMBERS',
			component: {
        type: 'ref',
        configuration: {
          collection: Collections.Companies,
          clearValue: [],
					multiple: true,
          search: {key: '/name', label: 'NAME'},
          display: {key: '/name', label: 'MEMBERS'},
          table: {
            tableColumns: [
              {key: '/name', label: 'NAME'},
              {key: '/position', label: 'POSITION'}
            ]
          }
        }
      }
		},
		...TEMPLATE.definitions,
		...COMPANY.definitions,
	},
	metadata: {
		docIdPrefix: 'int',
		subCollections: [
      {name: 'notes'}
    ]
	}
}