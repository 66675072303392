import {PipeType} from '../enums/pipe-type.enum';
import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';
import JSX from '../jsx.compiler';
import {CREATED_ON} from './shared/created-on';

export const ACCOUNTING_DOCUMENTS_INVOCATIONS_MODULE: Module = {
	id: Collections.AccountingDocumentsInvocationsSub(),
	name: 'ACCOUNTING_DOCUMENTS_INVOCATIONS',
	schema: {
		properties: {
			id: {type: 'string'},
			account: {type: 'string'},
			fiscalYear: {type: 'number'},
			description: {type: 'string'},
			status: {type: 'string'},
			file: {type: 'string'},
			...CREATED_ON.property
		}
	},
	layout: {
		sort: CREATED_ON.sort,
		table: {
			hideAdd: true,
			hideEdit: true,
			hideImport: true,
			hideExport: true,
			tableColumns: [
				{
					key: '/account',
					label: 'ACCOUNT',
					populate: {
						collection: Collections.InvoicingAccounts
					}
				},
				{key: '/fiscalYear', label: 'FISCAL_YEAR'},
				{key: '/description', label: 'DESCRIPTION'},
				{key: '/status', label: 'STATUS', pipe: [PipeType.Titlecase]},
				{
					key: '/file',
					label: 'FILE',
					pipe: [
						PipeType.Custom,
						PipeType.Sanitize
					],
					pipeArguments: {
						0: v => JSX(<jms-e-file-download label='View' path={v} />)
					}
				},
			]
		}
	}
}