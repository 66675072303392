import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {CREATED_ON} from '../shared/created-on';

export const INVOICE_TEMPLATES_MODULE: Module = {
  id: Collections.InvoiceTemplates,
  name: 'INVOICE_TEMPLATES',
  layout: {
    editTitleKey: 'name',
    sort: CREATED_ON.sort,
    instance: {
      segments: [
				{
					title: 'GENERAL',
					fields: [
						'/name',
						'/description',
						'/template',
						'/filePrefix'
					]
				},
				{
					title: 'FORMATTING',
					fields: [
						'/language',
						'/currencyFormat',
						'/dateFormat'
					]
				}
			]
    },
    table: {
      tableColumns: [
        {key: '/name', label: 'NAME'},
				{key: '/language', label: 'LANGUAGE'},
        {key: '/description', label: 'DESCRIPTION'},
				{key: '/filePrefix', label: 'FILE_PREFIX'},
				{
					key: '/template',
					label: 'VIEW',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: v => `<a class="link" href="${v}">View</link>`
					}
				},
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string',},
      description: {type: 'string'},
			template: {type: 'string'},
			currencyFormat: {type: 'string'},
			language: {type: 'string'},
			dateFormat: {type: 'string'},
			filePrefix: {type: 'string'},
      ...CREATED_ON.property
    },
		required: [
			'name',
			'template'
		]
  },
  definitions: {
    name: {label: 'NAME'},
		description: {
			label: 'DESCRIPTION',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
		currencyFormat: {
			label: 'CURRENCY_FORMAT',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'US', value: 'en-US'},
						{name: 'DE', value: 'de-DE'},
						{name: 'HR', value: 'hr-HR'},
					]
				}
			}
		},
		dateFormat: {
			label: 'DATE_FORMAT',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'US', value: 'us'},
						{name: 'DE', value: 'de'},
						{name: 'HR', value: 'hr'},
					]
				}
			}
		},
		language: {
			label: 'LANGUAGE',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'ENGLISH', value: 'en'},
						{name: 'CROATIAN', value: 'hr'}
					]
				}
			}
		},
		filePrefix: {
			label: 'FILE_PREFIX'
		},
		template: {
			label: 'TEMPLATE',
			component: {
				type: 'file',
				configuration: {
					filePrefix: `/invoicing/templates/`,
					uploadMethods: [{
						id: 'file-manager',
						label: 'FILE_MANAGER.TITLE',
						component: JSX(<jms-e-file-manager-select />),
						configuration: {
							hidePath: false,
							hideFolders: false,
							allowUpload: true,
							minPath: `/invoicing/templates`,
							route: `/invoicing/templates`
						}
					}]
				}
			}
		},
    ...CREATED_ON.definition()
  },
	metadata: {
		docIdPrefix: 'inte'
	}
};
