import {Collections} from '../interfaces/collections';
import {Module} from '../interfaces/module.interface';

export const USER_EMPLOYMENT_INFORMATION: Module = {
	id: Collections.UserInfo,
	name: 'Employment Information',
	layout: {
		editTitleKey: 'employeeCode',
		instance: {
			segments: [{
				fields: [
					'/employeeCode',
					'/oib',
					'/bank',
					'/iban',
					'/address',
					'/city'
				]
			}]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			employeeCode: {type: 'string'},
			oib: {type: 'string'},
			bank: {type: 'string'},
			iban: {type: 'string'},
			address: {type: 'string'},
			city: {type: 'string'}
		}
	},
	definitions: {
		employeeCode: {label: 'EMPLOYEE_CODE'},
		oib: {label: 'OIB'},
		bank: {label: 'BANK_NAME'},
		iban: {label: 'IBAN'},
		address: {label: 'ADDRESS'},
		city: {label: 'CITY'}
	}
};
