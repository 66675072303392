import {ENV_CONFIG} from '../../../../env-config';

export const environment = {
  origin: '',
  production: true,
  timeStamp: '6/27/2021 - 8:55:00 PM',
  firebase: ENV_CONFIG.firebase,
  firebaseEmulators: false,
  storageBaseUrl: 'https://firebasestorage.googleapis.com/v0/b/jaspero-site.appspot.com/o/',
};
