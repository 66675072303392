import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {CREATED_ON} from '../shared/created-on';

export const INVOICING_ACCOUNTS_MODULE: Module = {
  id: Collections.InvoicingAccounts,
  name: 'INVOICING_ACCOUNTS',
  layout: {
    editTitleKey: 'name',
    sort: CREATED_ON.sort,
    instance: {
      segments: [
				{
					type: 'tabs',
					configuration: {
						tabs: [
							{
								title: 'GENERAL',
								fields: [
									'/name',
									'/oib',
									'/logo',
									'/taxRate',
									'/convertOn',
									'/address',
								]
							},
							{
								title: 'ACCOUNTING',
								fields: [
									'/accountingEmails'
								]
							}
						]
					}
				},
				{
					title: 'CONTENT',
					fields: [
						'/intro/en',
						'/intro/hr'
					]
				},
				{
					title: 'FOOTER',
					array: '/footer',
					arrayConfiguration: {
						reverse: false
					},
					fields: [
						'/en/title',
						'/en/description',
						'/hr/title',
						'/hr/description'
					]
				}
			]
    },
    table: {
      tableColumns: [
				{
					key: '/logo',
					label: 'LOGO',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
            0: v => `<img class="table-image" src="${v}" />`
          }
				},
        {key: '/name', label: 'NAME'},
        {key: '/oib', label: 'OIB'},
				{key: '/taxRate', label: 'TAX_RATE', pipe: [PipeType.Percent]},
        {key: '/address', label: 'ADDRESS', pipe: [PipeType.Sanitize]}
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string',},
      address: {type: 'string'},
			logo: {type: 'string'},
			taxRate: {type: 'number'},
			intro: {
				type: 'object',
				properties: {
					en: {type: 'string'},
					hr: {type: 'string'}
				}
			},
			oib: {type: 'string'},
			convertOn: {type: 'string'},
			accountingEmails: {type: 'array'},
			invoiceTypes: {type: 'array'},
			footer: {
				type: 'array',
				items: {
					type: 'object',
					properties: {
						en: {
							type: 'object',
							properties: {
								title: {type: 'string'},
								description: {type: 'string'}
							}
						},
						hr: {
							type: 'object',
							properties: {
								title: {type: 'string'},
								description: {type: 'string'}
							}
						}
					}
				}
			},
      ...CREATED_ON.property
    },
    required: [
			'name',
      'address'
    ]
  },
  definitions: {
    name: {label: 'NAME'},
		taxRate: {
			label: 'TAX_RATE',
			formatOnLoad: v => v ? v * 100 : 0,
			formatOnSave: v => v ? v / 100 : 0,
			component: {
				type: 'input',
				configuration: {
					type: 'number',
					suffix: '%'
				}
			}
		},
		logo: {
			label: 'LOGO',
			component: {
				type: 'image',
				configuration: {
					filePrefix: '/invoicing/accounts/',
					uploadMethods: [{
						id: 'file-manager',
						label: 'FILE_MANAGER.TITLE',
						component: JSX(<jms-e-file-manager-select />),
						configuration: {
							hidePath: false,
							hideFolders: false,
							allowUpload: true,
							minPath: '/invoicing/accounts',
							route: '/invoicing/accounts',
							filters: [{
								value: file => file.contentType.startsWith('image/')
							}]
						}
					}]
				}
			}
		},
		'intro/hr': {
			label: 'Intro (hr)',
			component: {
				type: 'tinymce'
			}
		},
		'intro/en': {
			label: 'Intro (en)',
			component: {
				type: 'tinymce'
			}
		},
		convertOn: {
			label: 'CONVERT_ON',
			hint: 'CONVERT_ON_HINT',
			component: {
				type: 'select',
				configuration: {
					dataSet: [
						{name: 'Issue', value: 'issue'},
						{name: 'Paid', value: 'paid'}
					]
				}
			}
		},
		accountingEmails: {
			label: 'ACCOUNTING_EMAILS',
			hint: 'ACCOUNTING_EMAILS_HINT',
			component: {
				type: 'chips'
			}
		},
    address: {
      label: 'ADDRESS',
      component: {
        type: 'tinymce',
      }
    },
		'footer/en/title': {label: 'TITLE_EN'},
		'footer/en/description': {
			label: 'DESCRIPTION_EN',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
		'footer/hr/title': {label: 'TITLE_HR'},
		'footer/hr/description': {
			label: 'DESCRIPTION_HR',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
    ...CREATED_ON.definition()
  },
	metadata: {
		docIdPrefix: 'ina',
		subCollections: [
			{name: Collections.Invoices}
		]
	}
};
