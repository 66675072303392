import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';
import {PipeType} from '../../enums/pipe-type.enum';

export const JOB_APPLICATIONS_MODULE: Module = {
  id: 'job-applications',
  name: 'Job Applications',
  layout: {
    editTitleKey: 'title',
    sort: {
      active: 'createdOn',
      direction: 'desc'
    },
    instance: {
      segments: [
        {
          title: 'GENERAL',
          type: 'card',
          fields: [
            '/name',
            '/email',
            '/message',
            '/position',
            '/cv',
            '/createdOn'
          ]
        }
      ]
    },
    table: {
      tableColumns: [
        {
          key: '/name',
          label: 'Name'
        },
        {
          key: '/email',
          label: 'Email'
        },
        {
          key: '/createdOn',
          label: 'Date',
          pipe: [PipeType.Date],
        },
        {
          key: '/position',
          label: 'Position',
          populate: {
            collection: 'job-openings',
            displayKey: 'title'
          }
        },
        {
          key: '/cv',
          label: 'CV',
          pipe: [
            PipeType.Custom,
            PipeType.Sanitize
          ],
          pipeArguments: {
            0: v => JSX(<jms-e-file-download label='View' path={v} />)
          }
        }
      ]
    }
  },
  schema: {
    properties: {
      id: {type: 'string'},
      name: {type: 'string'},
      email: {type: 'string'},
      message: {type: 'string'},
      cv: {type: 'string'},
      createdOn: {type: 'number'},
      position: {type: 'string'}
    },
    required: ['name', 'email']
  },
  definitions: {
    name: {label: 'Name'},
    email: {label: 'Email'},
    message: {
      label: 'Message',
      component: {
        type: 'tinymce'
      }
    },
    cv: {
      label: 'CV',
      component: {
        type: 'file'
      }
    },
    createdOn: {
      formatOnLoad: '(value) => value || Date.now()',
      component: {
        type: 'date',
        configuration: {
          includeTime: false,
          format: 'number'
        }
      }
    },
    position: {
      label: 'Position',
      component: {
        type: 'select',
        configuration: {
          populate: {
            collection: 'job-openings',
            nameKey: 'title'
          }
        }
      }
    }
  }
};
