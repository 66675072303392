import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';

export const EVENT_TYPES_MODULE: Module = {
	id: Collections.EventTypes,
	name: 'EVENT_TYPES',
	layout: {
		editTitleKey: 'name',
		instance: {
			segments: [
				{
					type: 'tabs',
					configuration: {
						tabs: [
							{
								title: 'General',
								fields: [
									'/name',
									'/description',
									'/color',
									'/documentTemplate'
								]
							},
							{
								title: 'Rules',
								fields: [
									'/canAdd',
									'/public',
									'/inheritsName',
									'/hasDescription',
									'/disablesDay',
									'/hasHours',
									'/minMembers',
									'/maxMembers',
									'/minDuration',
									'/maxDuration',
									'/maxDaily',
									'/maxYearly',
									'/hasMembers',
									{
										field: '/minMembers',
										deps: ['/hasMembers'],
										action: {
											type: 'show',
											eval: v => v.hasMembers
										}
									},
									{
										field: '/maxMembers',
										deps: ['/hasMembers'],
										action: {
											type: 'show',
											eval: v => v.hasMembers
										}
									},
									'/needsApproval',
									{
										field: '/canApprove',
										deps: ['/needsApproval'],
										action: {
											type: 'show',
											eval: v => v.needsApproval
										}
									}
								]
							}
						],
					}
				},
			]
		},
		table: {
			tableColumns: [
				{
					key: '/name',
					label: 'NAME',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: (v, r) => JSX(
							<div class={'flex ai-center'}>
								<span style={'width:20px;height:20px;border-radius:20px;background:' + r.color}></span>
								&nbsp;
								<span>{v}</span>
							</div>
						)
					}
				},
				{key: '/description', label: 'DESCRIPTION'},
				{key: '/minMembers', label: 'MIN_MEMBERS'},
				{key: '/maxMembers', label: 'MAX_MEMBERS'},
				{key: '/minDuration', label: 'MIN_DURATION'},
				{key: '/maxDuration', label: 'MAX_DURATION'},
				{key: '/maxDaily', label: 'MAX_DAILY'},
				{key: '/maxYearly', label: 'MAX_YEARLY'},
				{key: '/canAdd', label: 'CAN_ADD'},
				{key: '/public', label: 'PUBLIC', control: true},
				{key: '/inheritsName', label: 'INHERITS_NAME', control: true},
				{key: '/hasDescription', label: 'HAS_DESCRIPTION', control: true},
				{key: '/hasMembers', label: 'HAS_MEMBERS', control: true},
				{key: '/hasHours', label: 'HAS_HOURS', control: true},
				{key: '/disablesDay', label: 'DISABLES_DAY', control: true},
				{key: '/needsApproval', label: 'NEEDS_APPROVAL', control: true},
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			name: {type: 'string'},
			description: {type: 'string'},
			color: {type: 'string'},
			minMembers: {type: 'number'},
			maxMembers: {type: 'number'},
			minDuration: {type: 'number'},
			maxDuration: {type: 'number'},
			maxDaily: {type: 'number'},
			maxYearly: {type: 'number'},
			public: {type: 'boolean'},
			disablesDay: {type: 'boolean'},
			inheritsName: {type: 'boolean'},
			hasDescription: {type: 'boolean'},
			hasMembers: {type: 'boolean'},
			hasHours: {type: 'boolean'},
			needsApproval: {type: 'boolean'},
			canApprove: {type: 'string'},
			documentTemplate: {type: 'string'},
			canAdd: {type: 'array'}
		},
		required: [
			'name',
			'color'
		]
	},
	definitions: {
		description: {
			label: 'DESCRIPTION',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
		color: {
			label: 'COLOR',
			component: {
				type: 'input',
				configuration: {
					type: 'color'
				}
			}
		},
		minMembers: {
			label: 'MIN_MEMBERS',
			columnsMobile: 6
		},
		maxMembers: {
			label: 'MAX_MEMBERS',
			columnsMobile: 6
		},
		minDuration: {
			label: 'MIN_DURATION',
			columnsMobile: 6,
			component: {
				type: 'input',
				configuration: {
					type: 'number',
					suffix: 'min'
				}
			}
		},
		maxDuration: {
			label: 'MAX_DURATION',
			columnsMobile: 6,
			component: {
				type: 'input',
				configuration: {
					type: 'number',
					suffix: 'min'
				}
			}
		},
		maxYearly: {
			label: 'MAX_YEARLY',
		},
		canAdd: {
			label: 'CAN_ADD',
			component: {
				type: 'select',
				configuration: {
					multiple: true,
					populate: {
						collection: Collections.Roles
					}
				}
			}
		},
		canApprove: {
			label: 'CAN_APPROVE',
			component: {
				type: 'select',
				configuration: {
					multiple: true,
					populate: {
						collection: Collections.Roles
					}
				}
			}
		},
		documentTemplate: {
			label: 'DOCUMENT_TEMPLATE',
			component: {
				type: 'ref',
				configuration: {
					collection: Collections.DocumentTemplates,
					clearValue: null,
					search: {key: '/name', label: 'NAME'},
					display: {key: '/name', label: 'DOCUMENT_TEMPLATE'},
					table: {
						tableColumns: [
							{key: '/name', label: 'NAME'},
							{key: '/description', label: 'DESCRIPTION'}
						]
					}
				}
			}
		}
	}
};