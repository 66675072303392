export const SHARED_CONFIG: {
	projectId: string,
  cloudRegion: 'us-central1' | 'us-east1' | 'us-east4' | 'europe-west1' | 'europe-west2' | 'asia-east2' | 'asia-northeast1';
	baseCurrency: string;
	timeZone: string;
	startingYear: number;
	holidays: Array<{
		name: string;
		month: number;
		day: number;
	}>;
	workingHours: {
		start: number;
		end: number;
	};
	website: {
		url: string;
	};
} = {
	projectId: 'jaspero-jms',
	cloudRegion: 'us-central1',
	baseCurrency: 'EUR',
	timeZone: 'Europe/Zagreb',
	startingYear: 2019,
	/**
	 * Holidays that repeat each year
	 */
	holidays: [
		{
			name: 'New Years Day',
			month: 0,
			day: 1
		},
		{
			name: 'Three Kings Day',
			month: 0,
			day: 6
		},
		{
			name: 'First of May',
			month: 4,
			day: 1
		},
		{
			name: 'Independence Day',
			month: 4,
			day: 30
		},
		{
			name: 'Antyfashist day',
			month: 5,
			day: 22
		},
		{
			name: 'Croatina Thanksgiving',
			month: 7,
			day: 5
		},
		{
			name: 'Marrys Day',
			month: 7,
			day: 15
		},
		{
			name: 'All Saints Day',
			month: 10,
			day: 1
		},
		{
			name: 'Vukovar Day',
			month: 10,
			day: 18
		},
		{
			name: 'Christmas',
			month: 11,
			day: 25
		},
		{
			name: 'St. Stephen\'s Day',
			month: 11,
			day: 26
		}
	],
	workingHours: {
		start: 9,
		end: 17
	},
	website: {
		url: 'https://jaspero.co/'
	}
}
