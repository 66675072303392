import {PipeType} from '../../enums/pipe-type.enum';
import {Collections} from '../../interfaces/collections';
import {Module} from '../../interfaces/module.interface';
import JSX from '../../jsx.compiler';

export const INVOICE_TAGS_MODULE: Module = {
	id: Collections.InvoiceTags,
	name: 'TAGS',
	layout: {
		sort: {
			active: 'name',
			direction: 'asc'
		},
		instance: {
			segments: [
				{
					title: 'GENERAL',
					fields: [
						'/name',
						'/description',
						'/color'
					]
				},
			]
		},
		table: {
			tableColumns: [
				{
					key: '/color',
					label: 'COLOR',
					pipe: [PipeType.Custom, PipeType.Sanitize],
					pipeArguments: {
						0: color => JSX(<span class="tag" style={'background-color:' + color}>{color}</span>)
					}
				},
				{key: '/name', label: 'NAME'},
				{key: '/description', label: 'DESCRIPTION'},
			]
		}
	},
	schema: {
		properties: {
			id: {type: 'string'},
			name: {type: 'string'},
			color: {type: 'string', default: '#000000'},
			description: {type: 'string'},
		},
		required: [
			'name'
		]
	},
	definitions: {
		'name': {label: 'NAME'},
		'description': {
			label: 'DESCRIPTION',
			component: {
				type: 'textarea',
				configuration: {
					rows: 5
				}
			}
		},
		'color': {
			label: 'COLOR',
			component: {
				type: 'input',
				configuration: {
					type: 'color'
				}
			}
		},
	},
	metadata: {
		docIdPrefix: 'int'
	}
};
