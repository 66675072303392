export const MONTHS = [
	'Siječanj',
	'Veljača',
	'Ožujak',
	'Travanj',
	'Svibanj',
	'Lipanj',
	'Srpanj',
	'Kolovoz',
	'Rujan',
	'Listopad',
	'Studeni',
	'Prosinac'
];